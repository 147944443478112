.tabContainer { background:#fff; margin:0 auto;
    .tabSelect { display:none; }

    .tabs { display:flex; flex-direction:row; list-style-type:none; margin:0 auto; padding:0 5vw;
        li { flex:1; text-align:center; margin:0; padding:10px 0 20px; font-size:1rem; line-height:1.3rem; cursor:pointer; transition:all 200ms; text-transform:uppercase; position:relative; word-wrap:break-spaces; display:flex; flex-direction:column; justify-content:flex-end;
            &:after { content:''; position:absolute; bottom:-1px; left:50%; width:0; height:3px; transform:translateX(-50%); transition:width 200ms; z-index:1; background:var(--maincolor); }

            &.current { color:var(--maincolor); font-weight:bold; }

            &.current,
            &:hover { color:var(--maincolor);
                &:after { width:100%; }
            }
            p { font-style:normal; }
        }
    }

    .tabBackgroundBox { background-position:top center; background-repeat:no-repeat; background-size:100% auto;
        .tabContent { position:relative; padding:3.5vw 2.5vw 5vw; max-width:1920px; margin:0 auto; box-sizing:border-box;
            .tab { flex-direction:row; justify-content:center; box-sizing:border-box; margin:0 auto; transition:all 200ms; opacity:0; display:none;
                &.current { opacity:1; display:flex }

                &.left {
                    .imageBox { order:2; }
                    .textBox { order:1; }
                }

                .textBox { order:2; padding:0 2.5vw; flex:1 1 0; white-space:normal; display:flex; flex-direction:column; justify-content:center; box-sizing:border-box;

                    .text {

                        a {
                            margin: 2rem 0 0;
                            display: block;
                            background: var(--maincolor);
                            height: 40px;
                            line-height: 40px;
                            border-radius: 5px;
                            box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
                            color: #fff;
                            text-decoration: none;
                            padding: 5px 1rem;
                            width: 276px;
                            text-align: center;
                        }
                    }
                }

            }
        }
    }

}

@media (min-width:1921px) {
    .tabContainer {
        .tabBackgroundBox {
            .tabContent { padding:2.5vw 2vw 2.5vw; max-width:2300px;
                .tab {
                    .textBox { padding:0 2vw; }
                }
            }
        }
    }
    max-width: 2115px;
}


@media (max-width:1024px) {
    .tabContainer {
        .headlines {
            .logoBox { width:100px; }
        }

        .tabBackgroundBox { background:none !important;
            .tabContent { padding:0;
                .tab { flex-direction:column; padding:0;
                    &.left {
                        .imageBox { order:1; }
                        .textBox { order:2; }
                    }

                    .imageBox { order:1; }
                    .textBox { width:100%; order:2; padding:5% 10%;
                        .textBoxInner {
                            .headline { margin:1rem 0; }
                            p { font-size:1rem; line-height:1.3rem; }
                        }
                    }

                }
            }
        }
    }
}

@media (max-width:800px) {
    .tabContainer {
        .headlines {
            .logoBox { display:none; }
        }

        .tabBackgroundBox {
            .tabContent {
                .tab {
                    &.box {
                        .textBox { padding:5% 10%; }
                    }
                }
            }
        }
    }
}
