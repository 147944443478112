.startBox {
  height: 75%;

  .startBoxSlider {
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;

    .slides {
      height: 100%;
      width: 100%;
      overflow: hidden;
      position: relative;
      z-index: -1;

      .slidesBox {
        width: 100%;
        height: 100%;
        position: relative;
        z-index: -1;

        &.prev {
          .slide {
            left: -100%;

            &.currentSlide {
              left: 0;
              z-index: 30;
            }

            &.prevSlide {
              left: 100%;
              z-index: 10;
            }
          }
        }

        &.stop {
          .slide {
            transition: none;
          }
        }

        .slide {
          position: absolute;
          width: 100%;
          height: 100%;
          flex-direction: row;
          box-sizing: border-box;
          //background: #fff;
          transition: all 400ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
          overflow: hidden;
          left: 100%;
          z-index: 10;
          display: flex;

          &:before {
            z-index: 100;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, .3) 50%, rgba(0, 0, 0, 0.5) 100%);
          }

          &.currentSlide {
            left: 0;
            z-index: 30;
          }

          &.prevSlide {
            left: -100%;
            z-index: 10;
          }

          .imgBox {
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 90;

            .lazyLoad {
              width: 100%;
              height: 100%;
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              position: relative;

            }
          }

          video {
            width: 100%;
            position: fixed;
            z-index: 90;
          }
        }
      }

    }

    .textBox {
      position: absolute;
      top: 50%;
      left: 50%;
      max-width: 90%;
      width: 100%;
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 200;
      opacity: 0;

      &.current {
        opacity: 1;

        .headlines {
          opacity: 1;
        }
      }

      .headlines {
        opacity: 0;
        transition: all 500ms;
        padding: 0;

        .headline {
          color: #fff !important;
          margin: 50px 0 0;
          text-shadow: 0 5px 5px rgba(0, 0, 0, .2);
        }

        .subheadline {
          color: #fff !important;
          text-shadow: 0 5px 5px rgba(0, 0, 0, .2);
        }
      }

      .alucaBox {
        margin-top: 100px;
        display: inline-block;
        background: var(--maincolor);
        height: 40px;
        line-height: 40px;
        border-radius: 5px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
        color: #fff;
        text-decoration: none;
        padding: 5px 1rem;
        width: 276px;
      }
    }

    .description {
      position: absolute;
      bottom: 2.5vw;
      left: 5vw;
      color: #fff;

      .descriptions {
        opacity: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 200ms;
        white-space: nowrap;
        line-height: 1.5rem;

        &.current {
          opacity: 1;
        }

        .headline {
          font-weight: 700;
        }

        .subheadline {
          font-style: italic;
        }
      }
    }

    .bubbleBox {
      position: absolute;
      left: 50%;
      bottom: 5%;
      transform: translateX(-50%);
      z-index: 100;
      white-space: nowrap;

      .bubble {
        display: inline-block;
        margin: 0 5px;
        width: 10px;
        height: 10px;
        border: 2px solid #fff;
        border-radius: 100%;
        transition: background-color 200ms;
        cursor: pointer;

        &.current {
          background: #fff;
        }
      }
    }
  }

}


@media (max-width: 1024px) {
  .startBox {

    .startBoxSlider {

      .slides {

        .slidesBox {
          .slide {
            flex-direction: column;

            .imgBox {
              .lazyLoad {
                background-size: cover;
                background-position: center center;

                &:after {
                  background-size: auto 100% !important;
                }
              }
            }

            video {
              height: 100%;
              width: auto;
              transform:translateX(-50%);
            }
          }
        }

      }

      .textBox {
        max-width: 80%;

        .logoBox {
          .logo {
            height: 80px
          }
        }

        .headlines {
          .headline {
            margin-top: 5%;
          }
        }

        .alucaBox {
          svg {
            height: 40px;
          }
        }
      }

      .description {
        display: none;
      }
    }

  }
}


@media (max-width: 400px) {
  .startBox {
    .startBoxSlider {
      .textBox {
        .logoBox {
          .logo {
            height: 60px
          }
        }
      }
    }
  }
}
