$color: var(--bgcolor);
$startcolor: var(--startbgcolor);
$endcolor: var(--endbgcolor);


.imprint_container {
    background: #fff;
}
.imprint { transition:all 200ms; max-height:0; overflow:hidden;
    background: var(--bgcolor);
    background: radial-gradient(ellipse at center, $startcolor 0%, $endcolor 100%);

    .sections { max-width:1920px; width:100%; display:flex; flex-direction:row; margin:0 auto; padding:0 2.5vw; position:relative; box-sizing:border-box;
        .close { position:absolute; top:20px; right:2.5%; color:#fff; z-index:199; font-size:1.5rem; width:50px; height:50px; line-height:50px; text-align:center; cursor:pointer; transition:all 200ms; border-radius:5px;
            &:before { line-height:50px; }
            &:hover { background:rgba(var(--bgcolor),1); color:var(--textcolor); }
        }

        section { width:50%; padding:5vw 2.5vw; line-height:1.3rem; color:#fff; white-space:pre-line; text-align:left; box-sizing:border-box;
            div { margin-top:20px; }

            hr { width:55%; margin:0; border:0; border-bottom:1px solid #fff; }
            a { color:#fff; }
            p { font-style: normal; }
        }

        .disableCookie { text-decoration:underline; cursor:pointer; }
    }
}

@media (min-width:1921px) {
    .imprint {
        .sections { max-width:2300px; padding:0 2vw;
            section { width:50%; padding:2.5vw 2vw; }
        }
    }
}

@media (max-width:1024px) {
    .imprint {
        .sections { flex-direction:column;
            section { padding:2.5% 2.5% !important; width:100%; }
        }
    }
}
