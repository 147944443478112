.mapBox {
  background: #fff;

  .location {
    max-width: 1920px;
    margin: 2.5vw auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 5vw;
    box-sizing: border-box;

    .left {
      display: flex;
      flex-direction: row;
      flex: 1;

      .image {
        width: 100%;
        max-width: 120px;
        height: 120px;
        overflow: hidden;
        position: relative;
        border-radius: 100%;
        border: 3px solid #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, .3);

        .lazyLoad {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          transition: all 200ms;
        }
      }

      .text {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.5rem;

        a {
          text-decoration: none;
          color: var(--maincolor);
        }
      }
    }

    .right {
      display: flex;
      flex-direction: row;
      flex: 1;

      address {
        font-style: normal;
        flex: 1;
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.5rem;
        padding-right: 20px;

        .street {
          white-space: pre-line;
        }
      }

      svg {
        height: 80px;
        fill: var(--maincolor);
        margin: auto auto;
      }
    }
  }

  .mapBoxInner {
    height: 400px;

    #map {
      width: 100%;
      height: 400px;

      .infoBox {
        font-size: 1rem;

        svg {
          width: 150px;
        }

      }
    }

    .gm-style-iw-d {
      padding-right: 20px;
    }

    .gm-ui-hover-effect {
      top: 0 !important;
      right: 0 !important;
    }
  }

  .loadMap {
    background: #e6e6e6;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      cursor: pointer;
      font-size: 1.25rem;
      line-height: 50px;
      margin: 20px auto;
      border-radius: 6px;
      border: 0;
      padding: 0 1rem;
      transition: all 200ms;
      position: relative;
      text-transform: uppercase;
      color: #fff;
      background: var(--maincolor);

      &:hover {
        background: var(--maincolor);
        border-color: var(--maincolor);
        color: #fff;
      }

      &:disabled {
        border: 0 !important;
        color: #fff !important;
        background: #ccc !important;
      }

      .loader {
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        margin: auto;
        width: 1.3rem;
        height: 1.3rem;
        border-radius: 100%;
        animation: spinLoader 1.5s linear infinite;
        animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
        transform: translate(-50%, -50%) rotate(0);
        border: 5px solid rgba(255, 255, 255, 0);
        border-top: 5px solid #fff;
      }
    }
  }
}

@media (min-width: 1921px) {
  .mapBox {
    .location {
      max-width: 2300px;
      margin: 2vw auto;
      padding: 0 2.5vw;
    }
  }
}

@media (max-width: 1024px) {
  .mapBox {
    .location {

      .image, svg {
        display: none;
      }

      .left {

        .text {
          flex: 1;
          padding: 20px 0;
          text-align: center
        }
      }

      .right {
        address {
          padding: 20px 0;
          text-align: center;
        }
      }
    }

    .mapBoxInner {
      height: 250px;

      #map {
        height: 250px;
      }
    }
  }
}

@media (max-width: 800px) {
  .mapBox {
    .location {
      flex-direction: column;

      .left {
        .text {
          padding: 20px 0 0;
        }
      }

      .right {
        address {
          padding: 20px 0 0;
        }
      }
    }
  }
}
