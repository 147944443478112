.lightbox { position:fixed; top:0; right:0; bottom:0; left:0; background:rgba(0,0,0,.7); z-index:2000; overflow-x:hidden; overflow-y:visible; width:100%; height:100%; cursor:pointer;

    .prevSlide,
    .nextSlide { max-width:80px; max-height:80px; width:4%; position:absolute; top:50%; left:0; transform:translateY(-50%); border-radius:0 5px 5px 0; z-index:20; transition:all 200ms; color:#ccc; cursor:pointer; background:rgba(255,255,255, .6);
        &:hover { background:var(--maincolor);
            svg { fill:#fff; }
        }
        svg { display:flex; flex-direction:column; justify-content:center; text-align:center; width:90%; height:90%; fill:rgba(0,0,0,.6); padding:5%; }
    }
    .nextSlide { left:auto; right:0; border-radius:5px 0 0 5px; }

    .slideBox { background:#fff; position:absolute; top:5%; left:50%; transform:translateX(-50%); box-sizing:border-box; border:20px solid #fff; overflow:hidden; width:90%; max-width:1920px; height:100%; max-height:87%; border-radius:10px; box-shadow:0 0 10px rgba(0,0,0, .4); cursor:auto;
        .slides { height:100%; display:flex; flex-direction:row; position:relative; left:0; transition:all 200ms; z-index:10;
            .slide { flex:1; height:100%; width:100%; min-width:100%; display:flex; flex-direction:row; box-sizing:border-box; order:4; border-radius:5px; overflow:hidden;
                &.prev { order:0; }
                &.current { order:1; }
                &.next { order:2; }

                .images { height:100%; width:100%; overflow:hidden; display:flex; position:relative;
                    .navigation { position:absolute; top:0; bottom:0; width:33%; cursor:pointer;
                        &.close { left:33%; }
                        &.next { left:66%; }
                    }


                    .img { width:100%; height:100%;
                        .lazyLoad { width:100%; height:100%; background-position:center center; background-size:cover; }
                    }

                    .data { position:absolute; right:0; bottom:0; left:0; background:rgba(0,0,0,.5); color:#fff; padding:15px;
                        .description { font-style:italic; }
                        .details { margin-top:10px; font-style:italic; }
                    }
                }
            }
        }
    }


    .bubbleBox { position:absolute; right:0; bottom:2.5%; left:0; display:flex; flex-direction:row; justify-content:center; margin:0 5vw; z-index:20;
        .bubble { width:10px; height:10px; margin:0 5px; cursor:pointer; border-radius:100%; border:2px solid #fff;
            &.current { background:#fff; }
        }
    }

}

@media (max-width:1024px) {
    .lightbox {
        .slideBox { max-height:70%; top:50%; transform:translate(-50%,-50%); border-width:5px; }

        .bubbleBox {
            .bubble { border-width:2px; }
        }
    }
}

@media (min-width:1920px) {
    .lightbox {
        .slideBox { max-width:80%; max-height:70%; top:50%; transform:translate(-50%,-50%); border-width:5px; }

        .bubbleBox {
            .bubble { border-width:2px; }
        }
    }
}