@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?cmhx99');
    src:  url('../fonts/icomoon.eot?cmhx99#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?cmhx99') format('truetype'),
    url('../fonts/icomoon.woff?cmhx99') format('woff'),
    url('../fonts/icomoon.svg?cmhx99#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-more:before {
    content: "\e90d";
}
.icon-info:before {
    content: "\e90c";
}
.icon-baugrube:before {
    content: "\e900";
}
.icon-beratung:before {
    content: "\e901";
}
.icon-boden:before {
    content: "\e902";
}
.icon-boeschung:before {
    content: "\e903";
}
.icon-burger:before {
    content: "\e904";
}
.icon-close:before {
    content: "\e905";
}
.icon-deponie:before {
    content: "\e906";
}
.icon-download:before {
    content: "\e907";
}
.icon-fels:before {
    content: "\e908";
}
.icon-forschung:before {
    content: "\e909";
}
.icon-gruendung:before {
    content: "\e90a";
}
.icon-hochwasser:before {
    content: "\e90b";
}
.icon-logo_pin:before {
    content: "\e910";
}
.icon-next:before {
    content: "\e911";
}
.icon-planung:before {
    content: "\e912";
}
.icon-prev:before {
    content: "\e913";
}
.icon-pruefung:before {
    content: "\e914";
}
.icon-tunnel:before {
    content: "\e915";
}
.icon-verkehr:before {
    content: "\e916";
}
.icon-wasser:before {
    content: "\e917";
}
.icon-wasserbau:before {
    content: "\e918";
}

