body, html { height:100%; }
body { background:#fff; font:normal 16px/1.4em; font-weight:300; color:var(--textcolor); position:relative; overflow-x:hidden; overflow-y:auto;
    &.noscroll { overflow:hidden; }
}

input, textarea, select, option { font:normal 18px/1.4em; color:#233746; }

input { box-shadow: none !important; }
:-webkit-autofill { color: #fff !important; }
textarea:focus, input:focus{
    outline: none;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */


textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder { color:#969696; text-align:left; }

textarea:-moz-placeholder,
input:-moz-placeholder { color:#969696; text-align:left; } /* FF 4-18 */

textarea::-moz-placeholder,
input::-moz-placeholder { color:#969696; text-align:left; } /* FF 19+ */

textarea:-ms-input-placeholder,
input:-ms-input-placeholder { color:#969696; text-align:left; } /* IE 10+ */

a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.external { text-decoration:none; }

strong { font-weight:600; }

p { margin:0; }

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}

.lazyLoad { opacity:0;
    &.loaded { opacity:1; transition:opacity 500ms; }
}


.horizontalShadow { width:100%; height:0; position:relative; z-index:1;
    &:before { content:''; display:block; width:100%; height:15px; position:absolute; top:0; right:0; left:0; border-top:1px solid #d9d9d9; background:url(../img/shadow.png) top center no-repeat; background-size:90% 15px; }
}
.horizontalLine { border-bottom:1px solid #d9d9d9; }

h1 { font-size:4.0625rem; line-height:4.5625rem; font-weight:700; margin:0; text-transform:uppercase; }
h2 { font-size:3rem; line-height:3.5rem; font-weight:700; margin:0; color:var(--maincolor); text-transform:uppercase; }
h3 { font-size:1.75rem; line-height:2.25rem; font-weight:300; margin:0; text-transform:uppercase; }
h4 { font-size:1.25rem; line-height:1.75rem; font-weight:700; margin:0; text-transform:uppercase; }
h5 { font-size:1rem; line-height:1.5rem; font-weight:700; margin:0; }
p { font-size:1rem; line-height:1.5rem; font-style:italic; }


.headlines { padding:4vw 5vw; position:relative;
    h2,
    h3 { margin:0; text-align:center; text-transform:uppercase; }

    .logoBox { position:absolute; right:5vw; top:50%; width:150px; transform:translateY(-50%);
        .logo { width:100%; }
    }
}

#root { height:100%;

    .pageContainer { height:100%;
        #content { height:100%; }
    }

    .load { position:fixed; top:0; right:0; left:0; bottom:0; transition:opacity 200ms; background:#fff; display:flex; flex-direction:column; justify-content:center; opacity:1;
        .spinner { margin:auto;
            div { width:15px; height:15px; background-color:var(--maincolor); border-radius:100%; display:inline-block; animation:sk-bouncedelay 1.4s infinite ease-in-out both;
                &.bounce1 { animation-delay:-0.32s; }
                &.bounce2 { animation-delay:-0.16s; }
                &.bounce3 { animation-delay:-0.12s; }
            }
        }
    }

    .loading { position:relative; display:flex; flex-direction:column; justify-content:center; margin-left:10px;
        .spinner { margin:auto;
            div { width:5px; height:5px; background-color:#333; border-radius:100%; display:inline-block; animation:sk-bouncedelay 1.4s infinite ease-in-out both;
                &.bounce1 { animation-delay:-0.32s; }
                &.bounce2 { animation-delay:-0.16s; }
                &.bounce3 { animation-delay:-0.12s; }
            }
        }
    }

    footer { position:relative; display:flex; flex-direction:column; align-items:center; background:var(--bgcolor); overflow:hidden;
        .horizontalLine { position:absolute; top:0; }

        .footerInner { max-width:1920px; width:90%; margin:0 5%; display:flex; flex-direction:row; justify-content:space-between; margin-top:auto; transition:all 200ms; line-height:3rem; position:relative; color:#fff;
            .name {  }
            .links {
                span { color:#fff; text-decoration:none; text-transform:uppercase; margin-left:20px; cursor:pointer; }
            }
        }
    }
}

.lazyload-wrapper {
    width: 100%;
    height: 100%;
}

.CookieConsentOverlay {
    background: var(--consentbgcolor);
    position: fixed;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100vw;
    height: 100vh;
    z-index: 9999;

    .CookieConsent {
        position: fixed;
        top: 50%;
        left: 50%;
        bottom: auto !important;
        width: 40%;
        background: #fff;
        transform: translate(-50%, -50%);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        box-shadow: 0 5px 5px rgba(0, 0, 0, .2);

        .text {
            text-align: center;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            svg {
                height: 100px;
            }
        }

        .buttons {
            padding: 1rem;
            display: flex;
            justify-content: center;
            gap: 1rem;

            button {
                display: inline-block;
                height: 30px;
                line-height: 30px;
                border-radius: 5px;
                box-shadow: 0 5px 5px rgba(0, 0, 0, .2);
                color: #fff;
                text-decoration: none;
                padding: 5px 1rem;
                border: 1px solid #ccc;
                box-sizing: content-box;
                cursor: pointer;
                transition: all 200ms;

                &:hover {
                    opacity: .5;
                }

                &#rcc-decline-button {
                    background: #fff;
                    color: #646464;
                }

                &#rcc-confirm-button {
                    background: var(--maincolor);
                }
            }
        }
    }
}

@media (min-width:1921px) {
    .headlines { padding:3vw 2.5vw; }
}

@media (max-width:1280px) {
    html, body { font-size:14px; }

    #root {
        footer {
            .footerInner {
                .name,
                .links { margin:0; }
            }
        }
    }
}

@media (max-width:1024px) {
    .headlines {
        .logoBox { width:100px; }
    }

    .horizontalShadow { position:relative; top:0; z-index:20; }
}

@media (max-width:800px) {
    body, html { font-size:12px; }
    body { overflow:auto; position:relative; }

    h1 { font-size:3.5rem; }
    h2 { font-size:2.6rem; }
    h3 { font-size:1.5rem; }

    .horizontalShadow {
        &:before { background-size:90% 10px; }
    }

    #root {
        .pageContainer { position:relative; }

        .tabSelect { position:relative; display:block; margin:0;
            &:before { z-index:1; content:''; position:absolute; top:6px; right:10%; width:10px; height:10px; border-style:solid; border-width:0 0 2px 2px; border-color:transparent transparent var(--maincolor) var(--maincolor); transform:rotate(-45deg); }

            select { font-size:1.2rem; position:relative; cursor:pointer; border:0; width:100%; background:transparent; line-height:3rem; padding:0 10%; box-sizing:border-box; border-radius:25px; text-transform:uppercase; z-index:10; color:var(--maincolor); font-weight:bold; }
        }

        .tabs { display:none; }
    }
}

@media (max-width:600px) {
    .headlines {
        .logoBox { display:none; }
    }

    .horizontalShadow { position:relative; top:0; z-index:20; }
}

@media (max-width:400px) {
    body, html { font-size:12px; }
}
